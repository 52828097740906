main.home {
  color: $grey;

  .products {
    min-height: 100vh;
    background-color: #FFF;
    padding: 100px 0 0;
    overflow: hidden;

    .products__head {
      font-weight: bold;

      @media screen and (max-width: 640px) {
        font-size: 2rem;
      }
    }
    .products__desc {
      font-weight: 300;

      @media screen and (max-width: 640px) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @media screen and (max-width: 480px) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    &__item {
      width: 460px!important;
      margin: 0 10px;
      overflow: hidden;
      transform: skew(-10deg);
      margin-right: -50px;

      &__wrapper {
        background-size: cover !important;
        margin-left: -50px;
        width: 560px;
        height: 500px;
        transform: skew(10deg);
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 40px 60px;
        color: $light;
        font-weight: 300;
        img{
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          z-index: -1;
          width: 100%;
          max-width: 100%;
          @media(max-width: 641px) {
            margin-top: unset !important;
          }
          // visibility: hidden;
          // background-size: cover !important;
        }

      }
    }
    &__carousel {
      overflow: hidden;
    }
    // .slick-cloned {
    // }

    .slick-slide {
      padding-left: 60px;

      &[data-slick-index="0"] {
        position: relative;

        &:before {
          content: "";
          position: absolute;
          left: -100%;
          top: 0;
          z-index: 9;
          width: 460px !important;
          height: 485px;
          margin: 0 10px;
          overflow: hidden;
          transform: skew(-10deg);
          background: #bf0d03;
          margin-left: 70px;
        }
      }
    }


    .slick-arrow {
      position: absolute;
      z-index: 9;
      background: #000;
      opacity: .6;
      border: 0;
      color: #FFF;
      border-radius: 100%;
      padding: 20px;
      height: 100px;
      width: 100px;
      display: flex;
      align-items: center;
      font-size: 30px;
      cursor: pointer;

      &.slick-prev {
        left: -50px;
        top: -80px;
        bottom: 0;
        margin: auto;
        outline: none !important;
        justify-content: flex-end;
      }
      &.slick-next {
        right: -50px;
        top: -80px;
        bottom: 0;
        margin: auto;
        outline: none !important;
        justify-content: flex-start;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }
    }

    @media screen and (max-width: 768px){
      min-height: 100vh;
      padding: 100px 0 20px;

      &__item {
        margin-left: 15px;
        margin-right: 0;
        width: 310px !important;
        // transform: skew(-7deg);

        &__wrapper {
          height: 360px;
          // transform: skew(7deg);
          background-size: 110%;
          background-position-x: 100%;
          background-position-y: top;
        }
      }
      // .slick-track {
      //   margin-left: 10vw;
      // }
      // .slick-slide {
      //   width: 300px;
      //   margin: 0 10px;
      //   overflow: hidden;
      // }

      .slick-slide {
        padding-left: 10px;

        &[data-slick-index="0"] {

          &:before {
            width: 310px !important;
            height: 360px;
            margin-left: 25px;
            transform: skew(-7deg);
          }
        }
      }
    }
  }

  .tww {

    min-height: 100vh;
    background: url('../img/home/bg_tww.jpg') no-repeat center center;
    background-size: cover;
    padding: 100px 0;

    @media screen and (max-width: 768px) {
      min-height: 100vh;
      background-size: 150%;
      background-position: bottom center;
      padding: 100px 0;
      text-align: center;

      .m-container {
        width: 100%;
        padding-right: 30px;
        padding-left: 30px;
      }

      .m-column {
        // flex-direction: column;
        align-items: flex-start!important;
        justify-content: center!important;
        .btn {
          margin: 0 10px;
          padding: 10px 15px;
        }
      }
    }
  }

  .aftersales {
    min-height: 100vh;
    background-color: #fff;
    padding: 100px 0;

    .btn-wrapper {
      display: flex;
      justify-content: center;
    }

    .img-wrapper {

      img {
        max-width: 100%;
      }
    }

    @media screen and (max-width: 768px) {
      min-height: 100vh;
      padding: 25vh 0;
    }
  }

  .test-drive {
    background-color: $whitegrey-darked;
    background-image: url('../img/home/bg_interior.jpg');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: contain;

    .content {
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__middle {
        text-align: left;

        .btn-wrapper {
          display: flex;
          justify-content: start;
        }
      }

      @media screen and (max-width: 768px) {
        min-height: 100vh;
        padding: 150px 0;
        align-items: flex-start;

        &__middle {
          text-align: center;

          .btn-wrapper {
            justify-content: center;
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      background-size: 155%;
      background-position: bottom right;
    }
  }

  .dealer {
    background-color: #fefefe;
    .contain {
      background: url('../img/home/map.png') no-repeat center right;
      background-size: 60%;
    }
    .content {
      min-height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &__middle {
        text-align: left;

        .btn-wrapper {
          display: flex;
          justify-content: start;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .m-block {
        display: block!important;
      }
      .content {
        min-height: 100vh;
        padding: 150px 0;
        align-items: flex-start;
        justify-content: center;

        &__middle {
          text-align: center;

          .btn-wrapper {
            justify-content: center;
          }
        }
      }

      .contain {
        background: url('../img/home/map.png') no-repeat;
        background-position-y: 70%;
        background-position-x: right;
        background-size: 95%;
      }
    }
  }

  .updates {
    background-color: #f9f9f9;
    padding: 100px 0;
    text-align: center;

    .btn-wrapper {
      display: flex;
      justify-content: center;
      margin: 15px 0;
    }

    @media screen and (max-width: 768px){
      padding: 80px 0 40px;
    }
  }
}


@media (max-width: 1367px){
  .banner .item .content__wrapper {
      padding: 7rem 0;
  }
}
@media (max-width: 768px){
  main.home .products{
    padding-top: 80px !important;
  }
  .row-mobile-center{
    justify-content: center;
  }
  main.home .products{
    min-height: unset !important;
  }
  main.home .tww{
    background-size: 165%;
  }
  main.home .test-drive .content{
    padding-top: 50px;
  }
  main.home .aftersales{
    padding: 10vh 0;
  }
  main.home .dealer .content{
    padding-top: 80px !important;
  }
}
@media (max-width: 640px){
  .aftersales p{
    padding: 10px 20px 20px !important;
  }
  .content {

    &__middle {
      margin-top: 100px;
    }
  }
}
@media (max-width: 480px){
  main.home .products .products__head{
    padding: 0 30px;
  }
  main.home .test-drive .content {
      padding-top: 50px;
      padding-bottom: 50px;
      min-height: 100vh;
  }
  main.home .dealer .content {
      padding-top: 30px !important;
  }
  main.home .dealer .content{
    min-height: 100vh;
  }
  main.home .dealer .content .content__middle br{
    display: none;
  }
}
@media (max-width: 376px){
  main.home .test-drive .content{
    min-height: 100vh !important;
  }
  main.home .tww{
    padding-top: 50px;
  }
  main.home .tww{
    background-size: 100%;
    min-height: 70vh;
  }
}

.pagination .page-item{
  border-radius: 15px 0;
}

.content__wrapper{
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
}
.content__inner{
  display: flex;
  color: #f8f9fa;
  flex-direction: column;
  align-items: flex-start;
}
.banner__caption{
  max-width: 50% !important;
  @media (max-width: 992px){
    max-width: 70% !important;
  }
  @media (max-width: 641px){
    max-width: unset !important;
  }
}

.banner .slick-dots{
  right: 13%;
  bottom: 100px !important;
  @media(max-width: 640px){
    bottom: 45px !important;
  }
}

.thewulingway{
  min-height: 100vh;
  height: auto;
  &__section{
    box-shadow: inset 0 150px 50px 0 rgba(202, 202, 202, 1);
    padding-top: 120px;
  }
  &__section2{
    background-size: cover!important;
    padding: 100px 0;
    background-position-x: center!important;
  }
  &__wrapper{
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    &.nopad{
      padding: unset !important;
    }
  }
  &__header{
      color: #666;
      span{
        color: #c1272d;
      }
  }
  &__content{
    color: #666;
    p{
      font-size: 16px;
    }
  }
  .video-content{
    width:100%;
    max-width:80%;
    margin: auto;
    @media(max-width: 640px){
      max-width: 100%;
    }
  }
  .vjs-poster{
    background-size: cover;
  }
  .video-js{
    .vjs-big-play-button{
    top: 50%;
    left: 50%;
    margin-top: -0.75em;
    margin-left: -1.5em;
    border-radius: 100%;
    width: 2em;
    height: 2em;
    font-size: 4em;
    background: #ea6060;
    border: none;
    .vjs-icon-placeholder{
      font-size: 64px;
      &:before{
        display: flex;
        align-items: center;
        justify-content: center;
        color: #3d3d3d;
      }
    }
    }
    &:hover{
      .vjs-big-play-button{
        background: linear-gradient(#941c1c,#a93b3b ) !important;
      }
    }
  }

}

main.article {
  color: $grey;
  padding: 120px 0 0;
  margin: 0;
  box-shadow: none;

  .description {

    &__wrapper {
      padding: 0 0 20px;
      border-bottom: solid 1px #979797;
      .article-title{
        text-align: left;
      }
      h1,h2,h3,h4,h5,h6{
        text-align: left;
      }
      p{
        text-align: justify;
      }
      img{
        max-width: 100%;
        height: auto;
      }
    }

    &__share {
      padding: 25px 0;
      display: flex;
      justify-content: start;
    }

    .article {

      &__image {
        width: unset;
        height: auto;
        max-width: 100%;
        margin: 20px 0 40px;
      }
    }

    @media screen and (max-width: 768px){
      padding: 0 15px;

      &__share {
        flex-direction: column;
        align-items: center;

        a {
          margin: 10px 0;
        }
      }
    }
  }

  .related {
    background: $whitegrey;
    padding: 100px 0 50px;

    .btn-wrapper {
      display: flex;
      justify-content: center;
      padding: 20px 0 0;
    }
  }

  .article__quote {
    margin: 35px 0 25px;
    margin-left: 50px;
    border-left: solid 1px rgba(151, 151, 151, 0.4);
    padding: 5px 0;
    padding-left: 35px;

    p:last-child {
      margin-bottom: 0;
    }

    &:before {
      content: '“';
      opacity: 0.2;
      font-family: 'Roboto';
      font-size: 144px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #575f66;
      height: 80px;
      display: flex;
      justify-content: flex-start;
      margin-top: -35px;
    }

    @media screen and (max-width: 640px) {
      margin-left: 15px;
      padding-left: 15px;
    }
  }

  @media screen and (max-width: 640px) {
    padding: 100px 0 0;
  }
}

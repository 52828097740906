// Purna Jual

.purna-jual {
  color: #575757;
  padding-top: 100px;
  padding-bottom: 100px;
  background-image: url(../img/purna-jual/bottom.jpg);
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
}

.purna-jual {
  .home {
    padding: 60px 0;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text-1 {
        font-size: 36px;
        text-align: center;
      }

      .text-2 {
        padding-bottom: 20px;
      }

      .btn-wrapper {
        margin-bottom: 45px;

        .btn-primary {
          width: 150px;
        }
      }

      .img-wrapper {

        img {
          max-width: 100%;
        }
      }
    }
  }
}

.purna-jual {
  .garansi {
    padding: 100px 0 50px;
    background-color: #f8f8f8;

    .container {
      max-width: 1000px;
    }

    .title {
      padding-bottom: 50px;
      @media(max-width: 640px){
        text-align: center;
      }
    }

    .box-card {
      margin-bottom: 50px;
      padding-right: 65px;
      display: flex;
      flex-direction: column;
      align-items: left;

      @media (max-width: 768px) {
        padding-right: 0px;
      }

      @media (max-width: 640px) {
        justify-content: center;
        align-items: center;
        padding-right: 0px;
      }

      .img-wrapper {
        margin-bottom: 20px;
      }

      .text-1,
      .text-2 {
        font-size: 16px;

        @media (max-width: 640px) {
          text-align: center;
        }
      }
    }
  }
}

.purna-jual {
  .custom {
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .box-card-wrapper {
      .box-card {
        display: flex;
        justify-content: center;
        padding: 35px 0;

        @media (max-width: 425px) {
          flex-direction: column;
        }

        .img-wrapper {
          margin-right: 30px;

          @media (max-width: 768px) {
            padding-bottom: 10px;
          }
          @media (max-width: 640px) {
            margin-right: unset;
            text-align: center;
          }
        }

        .content {
          @media (max-width: 640px) {
            text-align: center;
          }
          .text-1 {
            font-size: 28px;

            @media (max-width: 768px) {
              font-size: 18px;
            }
          }

          .text-2 {
            font-size: 16px;
            padding-right: 100px;

            @media (max-width: 768px) {
              padding-right: 0px;
              font-size: 15px;
            }
          }
          ul{
            li {
              margin-left: -20px;
              @media(max-width: 640px) {
                margin-bottom: 10px;
              }
            }
            @media(max-width: 640px){
              text-align: left;
            }
          }
        }
      }
    }
  }
}

.purna-jual {
  .call-center {
    padding-bottom: 350px;

    @media (max-width: 2560px) {
      padding-bottom: 750px;
    }

    @media (max-width: 1440px) {
      padding-bottom: 370px;
    }

    @media (max-width: 1366px) {
      padding-bottom: 350px;
    }

    @media (max-width: 1280px) {
      padding-bottom: 320px;
    }

    @media (max-width: 1024px) {
      padding-bottom: 250px;
    }

    @media (max-width: 991px) {
      padding-bottom: 230px;
    }

    @media (max-width: 768px) {
      padding-bottom: 170px;
    }

    @media (max-width: 640px) {
      padding-bottom: 120px;
    }

    @media (max-width: 480px) {
      padding-bottom: 75px;
    }

    @media (max-width: 425px) {
      padding-bottom: 50px;
    }

    @media (max-width: 375px) {
      padding-bottom: 40px;
    }

    @media (max-width: 360px) {
      padding-bottom: 30px;
    }

    @media (max-width: 320px) {
      padding-bottom: 10px;
    }

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .text-1 {
        font-size: 30px;
        text-align: center;
      }

      .text-2 {
        font-size: 18px;
      }
    }
  }
}

* {
  transition: all ease-in-out .25s;
}

$flag-icon-css-path: '../flags';

@import '../../node_modules/bootstrap/scss/bootstrap';
@import '../../node_modules/slick-carousel/slick/slick.scss';
@import '../../node_modules/flag-icon-css/sass/flag-icon.scss';
@import '../../node_modules/sweetalert2/src/animations.scss';
@import '../../node_modules/owl.carousel/src/scss/owl.carousel.scss';
@import '../../node_modules/owl.carousel/src/scss/owl.theme.default.scss';
@import '../../node_modules/owl.carousel/src/scss/animate.scss';
// @import '../../node_modules/video.js/src/css/vjs.scss';

// Settings
@import './settings';

// Variable
@import './variable';
@import './initialize';

// Components
@import './components/icons';
@import './components/dropdown';
@import './components/slider';
@import './components/header';
@import './components/footer';
@import './components/buttons';
@import './components/banners';
@import './components/articles';
@import './components/products-slider';

// Pages
@import './pages/home';
@import './pages/article';
@import './pages/news';
@import './pages/products';
@import './pages/dealer';
@import './pages/purna-jual';
@import './pages/milestone';
@import './pages/thewulingway';
@import './pages/promo';
@import './pages/karir';
@import './pages/contact';


.article {
  background: #FFF;
  margin: 15px 0;
  overflow: hidden;

  &__image {
    background-image: url('..//img/home/article-2.png');
    min-height: 200px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &__content {
    position: relative;
    padding: 25px 25px;
    text-align: left;
    display: flex;
    align-items: center;

    a {
      color: #575757;

      &:hover,
      &:focus {
        color: $red;
        text-decoration: none;

        .article__title {
          color: $red;
          text-decoration: none;
        }
      }

    }

    &__wrapper {
      .link {
        color: $red;
        margin: 0;

        &:hover,
        &:focus {
          letter-spacing: 1.5px;
          text-decoration: none;
        }
      }
    }
  }

  &__label {
    position: absolute;
    height: 45px;
    left: -10px;
    top: -45px;
    background: $darkred;
    color: #FFF;
    padding: 10px 25px;
    transform: skew(20deg);

    span {
      display: block;
      transform: skew(-20deg);
    }
  }

  &__title {
    // border-bottom: solid 1px #979797;
    padding: 5px 0;
    margin-bottom: 5px;
    color: #575757;
  }

  @media screen and (min-width: 991px) {
    &-big {

      .article__content {
        padding: 80px 25px 40px;
      }

      .article__content__wrapper {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        .link {
          color: $red;
          margin: 0;

          &:hover,
          &:focus {
            letter-spacing: 1.5px;
            text-decoration: none;
          }
        }
      }

      .article__label {
        position: relative;
        top: unset;
        margin: 0 0 15px;
        width: auto;
      }
    }
  }

  &-reverse {

    @media screen and (max-width: 992px){
      &-m {
        flex-direction: column-reverse;
      }
    }
  }
}

footer,
.footer {
  background: url('..//img/footer/bg.png') no-repeat center right;
  background-size: cover;
  color: #FFF;
  padding: 50px 0 0;

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0 10px;
    flex-wrap: wrap;

    .icon-wrapper {
      width: 30px;
      height: 30px;
    }

    .content {
      padding: 5px 5px;
      width: 90%;
    }


    @media screen and (max-width: 640px) {
      margin: 0px 0 10px;

      .share-wrapper {
        margin-left: 30px;
      }
    }
  }

  .separator {
    border-bottom: solid 1px rgba(255, 255, 255, .5);
    margin: 10px 0;
  }

  p,
  .p {
    margin: 0;
  }

  .links-wrapper {
    margin: 35px 0 25px;

    h6 {
      font-weight: 800;
      margin-bottom: 15px;
    }

    .link {
      display: block;
    }

    @media screen and (max-width: 768px) {
      margin: 15px 0 5px;
      margin-left: 35px;
    }
  }

  a,
  .link {
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    color: #FFF;
    font-weight: 300;

    &:hover,
    &:focus {
      color: #FFF;
      text-decoration: underline;
    }
  }

  .copy-wrapper {
    margin: 30px 0 25px 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @media screen and (max-width: 768px) {
      margin: 10px 0 20px 35px;
      flex-direction: column;

      a.link {
        margin: 5px 0;
      }

    }
  }

  @media screen and (max-width: 640px) {
    background-size: 160%;
    background-position-x: right;
    background-position-y: 80%;
    background-color: #bb1400;
  }
}
.fa.fa-phone{
  transform: rotate(90deg);
}
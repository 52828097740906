.dropdown {

  &-toggle {

    &::after {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f078";
      border: none;
      width: 0;
      height: 100%;
      -moz-osx-font-smoothing: grayscale;
      -webkit-font-smoothing: antialiased;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      line-height: 1;
      vertical-align: 0;
    }
  }
}

.section-karir {
  position: relative;
  min-height: 100vh;
  height: auto;
  background-size: cover !important;
  background-position: center !important;

  @media(max-width: 576px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .karir-hero-wrapper {
    padding-top: 100px;
    text-align: center;
    width: 80%;
    margin: auto;
    color: #fff;
    font-weight: bolder;
    text-shadow: 0 0 20px #090909;

    @media(max-width: 768px) {
      padding-top: 100px;
      padding-bottom: 75px;

      h1 {
        font-size: 36px;
      }
    }

    @media(max-width: 576px) {
      width: 90%;
      padding-top: 100px;
      padding-bottom: 75px;

      h1 {
        font-size: 34px;
      }
    }

    @media(max-width: 425px) {
      h1 {
        font-size: 26px;
      }

      h2 {
        font-size: 22px
      }
    }

    .karir-search-wrapper {
      justify-content: center;
      margin-top: 50px;

      @media(max-width: 576px) {
        margin-top: 25px;
      }
    }
  }
}

.section-list-job {
  padding: 50px 0;
  overflow: hidden;

  .heading-job {
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
    color: #666;

    span {
      color: #e60000;
    }
  }
}

.job-listing-wrapper {
  width: 80%;
  margin: auto;

  @media(max-width: 576px) {
    width: 100%;
  }

  .all-job {
    list-style: none;
    padding: 0;
    text-align: left;

    li {
      border-bottom: 3px solid #ccc;
      padding: 15px 0 10px;

      a {
        text-decoration: none;
        color: #333;
      }

      .job-list__txt {
        padding: 25px 0;
        transform: skew(-30deg);
        border-bottom-right-radius: 25px;
        border-top-left-radius: 25px;

        &:hover {
          background: #c4c2c2;
        }

        @media(max-width:576px) {
          transform: skew(0deg);
        }

        .job-list__txt__wr {
          transform: skew(30deg);
          padding: 0 50px;

          @media(max-width: 576px) {
            transform: skew(0deg);
            padding: 0 15px;
          }

          .job-list__ttl {
            text-transform: uppercase;
            font-weight: bolder;
            margin: 0;
            font-size: 20px;

            @media(max-width: 576px) {
              font-size: 18px;
            }

            @media(max-width: 480px) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.sm-nopad {
  @media(max-width: 576px) {
    padding: unset;
  }
}

.modal-karir {
  .modal-header {
    height: 0px;
    padding: 0px;

    .close {
      padding: 10px 15px;
      margin: 0px 0rem 0rem auto;
      position: relative;
      z-index: 2;
    }
  }

  .modal-dialog {
    @media(min-width: 576px) {
      max-width: 60%;
    }
  }

  .modal-content {
    border-radius: 4px;
    overflow: hidden;

    .modal-body {
      padding: unset;
    }
  }
}


.section-karir-detail {
  .modal {
    .modal-header {
      outline: none;
      .modal-title {
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: $grey;
        margin: 10px 0;
      }
    }

    .modal-body {
      padding-top: 30px !important;

      .btn-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 25px 0;
      }

      .wrapper-form {
        display: flex;
        flex-direction: column;

        .input-text {
          outline: none;
          border: 1px solid rgba(0, 0, 0, .11);
          margin-bottom: 15px;
          overflow: hidden;
          transition: background-color .2s ease;
          padding: 12px 15px;
          border-radius: 2px;
          position: relative;
          min-height: 25px;
          color: $grey;
        }

        .check-box {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;

          label {
            margin: 0 10px;
          }
        }
      }
    }

    .modal-footer {
      .text-center{
        width: 100%;
      }
      a {
        color: #c1272d;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  @media (min-width: 576px) {
    .modal-dialog {
      max-width: 600px !important;
    }
  }

  .bg-hero {
    overflow: hidden;

    img {
      width: 100%;
      margin-bottom: 50px;

      @media (max-width: 768px) {
        margin-top: 50px;
      }
    }
  }

  .title {
    font-size: 40px;
    color: $grey;
    text-align: center;
    margin-bottom: 30px;

    @media (max-width: 640px) {
      font-size: 25px;
    }
  }

  .sub-header {
    line-height: 72px;
    margin-top: 25px;
    margin-bottom: 12.5px;
    font-size: 16px;
  }

  .content {
    .wrapper-karir {
      margin: 50px 0;

      .sub-title-list {
        font-size: 32px;
        color: $grey;
        margin-top: 25px;
        margin-bottom: 12.5px;
      }

      .job-detail {
        color: $grey;

        li {
          padding: 5px 0;
        }
      }

      .img-style {
        list-style-image: url(../img/karir/karir-detail/list.png);
      }

      .btn {
        max-width: 200px;
      }


    }

    .text-red {
      color: #c1272d;
    }

    .wrapper-bottom {
      margin: 50px 0;

      .quotes {
        line-height: 1.5;
        position: relative;
        font-size: 25px;
        color: $grey;

        &:before {
          content: url(../img/karir/karir-detail/quotes-r.png);
          bottom: 0;
          position: relative;
          top: -10px;
          right: 5px;
        }

        &:after {
          content: url(../img/karir/karir-detail/quotes.png);
          bottom: 0;
          position: relative;
          top: -5px;
        }
      }
    }

    .wrapper-contact {
      margin: 50px 0;

      .contct-list {
        list-style: none;
        padding: 0;

        .center-list {
          margin-bottom: 20px;
          display: flex;
          align-items: center;

          .img-contact {
            width: 40px;
            height: 40px;
            display: inline-block;
            margin-right: 15px;
            flex-shrink: 0;
            background-image: url(../img/karir/karir-detail/contact-icons-dark.png);
          }

          .contact-word {
            background-position: -160px;
          }

          .contact-loca {}

          .contact-phon {
            background-position: -40px;
          }

          .contact-mail {
            background-position: -120px;
          }
        }
      }
    }
  }
}

@function _buildIcon($icon) {
  $icon: '<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">#{$icon}</svg>';
  @return $icon;
}
@function _buildIcon4($icon) {
  $icon: '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="50" viewBox="0 0 40 50">#{$icon}</svg>';
  @return $icon;
}

@function icon(
  $icon-name,
  $color,
  $stroke-color: transparent,
  $stroke-width: 0,
  $css: '' // arbitrary css
  ){
  $icons: (
    question: '<g fill="none" fill-rule="evenodd"><path fill="#{$color}" d="M41.91 7.196C37.266 2.554 31.108 0 24.545 0 17.983 0 11.824 2.554 7.182 7.196-1.79 16.168-2.413 30.53 5.635 40.217c-.79 1.589-2.056 3.438-3.936 4.362a2.285 2.285 0 0 0-1.256 2.409 2.302 2.302 0 0 0 1.92 1.931c.468.073 1.133.146 1.942.146 2.171 0 5.37-.51 8.64-2.866a24.427 24.427 0 0 0 11.59 2.917c6.356 0 12.649-2.46 17.384-7.195 4.643-4.642 7.197-10.8 7.197-17.363 0-6.562-2.565-12.72-7.207-17.362z"/><text fill="#FFF" font-family="Gotham-Bold, Gotham" font-size="35.721" font-weight="bold" letter-spacing=".378"><tspan x="14.884" y="37.465">?</tspan></text></g>',
    steer: '<path fill="#{$color}" fill-rule="evenodd" d="M25 0C11.215 0 0 11.215 0 25c0 13.57 10.871 24.643 24.361 24.984l.013.008-.003-.008c.21.005.418.016.629.016.211 0 .42-.01.63-.016l-.004.008.013-.008C39.13 49.642 50 38.57 50 25 50 11.215 38.785 0 25 0zM4.38 17.594C7.352 9.114 15.47 3.012 25 3.012c9.53 0 17.647 6.102 20.62 14.581.22 1.058.318 2.41-.373 3.05-.512.475-1.324.493-2.016.39-4.442-1.557-11.325-3.48-18.231-3.48-6.906 0-13.79 1.923-18.231 3.48-.693.103-1.504.085-2.016-.39-.692-.64-.592-1.992-.374-3.05zm16.06 27.785c-.592.525-1.594.446-2.357.276C11.185 43.52 5.763 38 3.75 31.044c-.177-.821-.248-1.772.205-2.225.422-.422.92-.504 1.239-.506 3.387.282 8.405 1.277 11.547 4.54 2.769 2.876 3.89 7.171 4.337 10.495.033.686-.068 1.523-.64 2.03zm4.259-12.849a5.421 5.421 0 1 1 0-10.843 5.421 5.421 0 0 1 0 10.843zm6.613 13.126c-.763.169-1.764.248-2.354-.277-.572-.51-.672-1.349-.639-2.036.448-3.323 1.568-7.615 4.336-10.49 3.142-3.263 8.16-4.257 11.548-4.54a1.74 1.74 0 0 1 1.239.506c.453.454.381 1.405.204 2.226-2.013 6.957-7.435 12.476-14.334 14.611z"/>'
  );

  $icon: _buildIcon(map-get($icons, $icon-name));
  @return url('data:image/svg+xml;utf8,#{$icon}');
}
@function icon4(
  $icon-name,
  $color,
  $stroke-color: transparent,
  $stroke-width: 0,
  $css: '' // arbitrary css
  ){
  $icons: (
    map-marker: '<path fill="#{$color}" fill-rule="evenodd" d="M19.99 0C31.01 0 39.98 8.994 40 20.042c0 1.258-.125 2.526-.355 3.763a19.55 19.55 0 0 1-1.474 4.623c-2.154 5.084-6.9 12.892-17.282 21.257a1.416 1.416 0 0 1-1.778 0C8.72 41.32 3.983 33.512 1.83 28.428a19.74 19.74 0 0 1-1.475-4.623A19.779 19.779 0 0 1 0 20.042C0 8.994 8.97 0 19.99 0zm-.347 30.712c5.365 0 9.72-4.491 9.72-9.998s-4.366-9.997-9.72-9.997c-5.354 0-9.72 4.49-9.72 9.997s4.356 9.998 9.72 9.998z"/>',
  );

  $icon: _buildIcon4(map-get($icons, $icon-name));
  @return url('data:image/svg+xml;utf8,#{$icon}');
}

.w-icon {
  display: inline-block;
  font-size: 0;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &-lg {
    width: 75px;
    height: 75px;
  }

  &-map-marker { background-image: icon4(map-marker, $red); }
  &-question { background-image: icon(question, $red); }
  &-steer { background-image: icon(steer, $red); }

  &-map-marker-stroke { background-image: icon4(map-marker, transparent, $red, 1); }
  &-question-stroke { background-image: icon(question, transparent, $red, 1); }
  &-steer-stroke { background-image: icon(steer, transparent, $red, 1); }

  &-map-marker-white { background-image: icon4(map-marker, #FFF); }
  &-question-white { background-image: icon(question, #FFF); }
  &-steer-white { background-image: icon(steer, #FFF); }

  &-map-marker-stroke-white { background-image: icon4(map-marker, transparent, #FFF, 1); }
  &-question-stroke-white { background-image: icon(question, transparent, #FFF, 1); }
  &-steer-stroke-white { background-image: icon(steer, transparent, #FFF, 1); }
}

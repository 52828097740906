.red-txt {
  color: $red;
  span{
    color: $grey;
  }
}

.big-txt {
  font-size: 150%;
  margin-top: 5px;
  font-weight: 800;
}

.fw-normal {
  font-weight: 300;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: bold;
}

.bold {
  font-weight: bold;
}

@media (min-width: 640px) {
  .hidden-sm-up {
    display: none;
  }
}

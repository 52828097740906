main.news {
  background: #f9f9f9;
  color: $grey;
  padding-top: 120px;

  .carousel {
    background: #f9f9f9;

    .article__title {
      color: #f9f9f9;
    }

    &__news {

      &__item {
        margin: 20px 15px;
        width: 620px!important;
        height: 400px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        color: $light;

        .article__content__wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 30px 45px 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;
          overflow: hidden;

          a {
            color: $light;
            margin: 0;
            padding: 0px 0;
          }

          .carousel__news__label {
            overflow: hidden;

            .article__label {
              position: relative;
              top: 0; left: -10px;
              margin: 15px 0;
            }
          }
        }
      }

      .slick-slide {
        opacity: .4;
        transition: all ease-in-out .3s;
      }

      .slick-current,
      .slick-active {
        opacity: 1;
      }

      @media screen and (max-width: 991px) {
        &__item {
          width: 520px !important;
          height: 360px;
        }
      }
      @media screen and (max-width: 480px) {
        &__item {
          width: 400px !important;
          // height: 230px;
        }
      }
    }
  }

  .popular {
    padding: 50px 0;

    &__slider {

      &__item {
        padding: 0 10px;
      }
    }
  }

  .news {

    &__updates {

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        .nav.nav-tabs {
          border: none;
          font-weight: 300;
        }
      }

      .article {
        margin: 20px 0;
      }

      @media screen and (max-width: 991px) {
        &__header {
          flex-direction: column;

          .nav.nav-tabs {
            width: max-content;
            margin: 20px 0;
          }
        }

        .m-scroll {
          overflow-x: scroll;
          overflow-y: hidden;
          width: 100%;

          ul {
            justify-content: flex-start;
          }
        }

        .article {
          margin: 10px 0;
        }
      }
    }
  }
}

main.news .carousel__news__item{
  position: relative;
}
main.news .carousel__news__item .article__content__wrapper{
    padding: 30px;
    position: absolute;
    bottom: 0 !important;
    justify-content: unset;
    width: 100% !important;
    height: unset !important;
    max-width: unset !important;
    background: linear-gradient(to bottom,rgba(0,0,0,0) 0,rgba(0,0,0,.8) 100%);
}
.news .slick-arrow{
    // display: block !important;
    position: absolute;
    font-size: 45px;
    top: 0;
    bottom: 0;
    height: 100%;
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 20;
    &:hover{
      cursor: pointer;
    }
}
.news .slick-prev{
  left: 50px;
}
.news .slick-next{
  right: 50px;
}
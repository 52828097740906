.slick-dots {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 15px 0;
  padding: 0;

  li {
    list-style: none;

    * {
      width: 30px;
      height: 5px;
      text-indent: -1000em;
      border-radius: 0;
      border: 0;
      margin: 5px 5px;
      background: $whitegrey-darked;
      outline: none;
      transform: skew(-15deg);
    }

    &.slick-active {
      * {
        background: $red;
      }
    }
  }
}

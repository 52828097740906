html,
body {
  font-family: 'Gotham';
  letter-spacing: .01em;
  line-height: 1.6;
  font-weight: 300;

  @media screen and (max-width: 480px){
    font-size: 14px;
  }
}

.w-container {

  @media screen and (max-width: 600px) {
    max-width: 400px;
  }
  @media screen and (max-width: 480px) {
    max-width: 350px;
  }
}

a:active {
  background: unset!important;
}



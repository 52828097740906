// Milestone

.milestone {
  color: #575757;
  padding-top: 130px;
  padding-bottom: 100px;

  .container {
  	max-width: 650px;
  }
}

.milestone {
  .title {
    .text-head {
      text-align: center;
    }
  }
}

.milestone {
  .content {
  	padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 425px) {
		padding-top: 100px;    	
    }
  }
}

.milestone {

  // Variables
  $bg-body: #f9f9f9;

  $red: #ee4d4d;
  $blue: #2b2e48;
  $primary-color: $red;
  $secondary-color: $blue;


  // Typography
  $base-font: helvetica, arial, tahoma, verdana;
  $base-font-title: "Dosis", arial, tahoma, verdana;

  $base-font-color: #726f77;

  // Timeline
  $timeline-color: $primary-color;


  // Mixins and Placeholders
  %clearfix {

    &:after,
    &:before {
      content: '';
      display: block;
      width: 100%;
      clear: both;
    }
  }

  @mixin prefix($prop, $val) {

    @each $prefix in '-webkit-',
    '-moz-',
    '-ms-',
    '' {
      #{$prefix}#{$prop}: $val;
    }
  }

  // Timeline
  #timeline {
    margin: 30px;
    padding: 0px;
    width: 90%;
    position: relative;
    @include prefix(transition, all .4s ease);

    &:before {
      content: "";
      width: 3px;
      height: 96%;
      background: $timeline-color;
      left: 0;
      top: 0;
      position: absolute;
      @media (max-width: 480px) {
      	height: 96%;
      }
    }

    &:after {
      content: "";
      clear: both;
      display: table;
      width: 100%;
    }

    .timeline-item {
      margin-bottom: 50px;
      position: relative;
      @extend %clearfix;
      &:last-child{

      }

      .timeline-icon {
        background: #bf0d03;
        width: 55px;
        color: #ffffff;
        text-align: center;
        padding-top: 16px;
        height: 55px;
        font-size: 14px;
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        margin-left: -25px;
        @include prefix(border-radius, 50%);
      }

      .timeline-content {
        width: 90%;
        float: right;
        background: #fff;
        padding: 0px;
        margin-top: -75px;
        margin-bottom: 200px;
        padding-left: 50px;
        img{
        	max-width: 100%;
        }
        @media (max-width: 480px) {
        	padding-left: 20px;
        }
        @media (max-width: 425px) {
        	margin-top: -50px;
        }
        @media (max-width: 375px) {
        	margin-top: -25px;
        	margin-bottom: 120px;
        }
        .img-hero{
        	padding-bottom: 10px;
        }
      }
    }
  }
}

.milestone{
	br{
		@media (max-width: 375px) {
			display: none;
		}
	}
}

.milestone #timeline .timeline-item:last-child .timeline-content{
	margin-bottom: 20px;
}

.timeline-icon{
  font-weight: 500;
}
.milestone .title .text-head{
  font-size: 36px;
}
.milestone{
  padding-top: 150px;
}
.milestone .content{
  padding-top: 125px;
}

@media (max-width: 426px){
  .milestone #timeline .timeline-item{
    margin-bottom: 5px;
  }
}
@media (max-width: 376px){
  .milestone .title .text-head {
      font-size: 28px;
  }
}
@media (max-width: 320px){
  .milestone {
      padding-top: 125px;
  }
  .milestone .content {
      padding-top: 75px;
  }
}
body{
  &.customize-support{
    header{
      padding-top: 32px;
    }
  }
}

header {
  position: fixed;
  top: 15px; left: 0;right: 0;
  z-index: 999;
  font-weight: 300;

  &.nav-up {
    top: -120px;
  }
  .navbar {
    padding: 0;
    font-size: 14px;
    color: #FFF;
    position: relative;

    &__top ,
    &__bottom {
      position: relative;
      width: 100%;
      background: $darkred;
      padding: 0px 0;
      height: 35px;

      &__menu,
      .menu {
        width: 100%;
        list-style: none;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        margin: 0;
        padding: 0;

        &__item {
          margin: 5px 15px;

          &-separator {
            border-left: solid 1px rgba(255, 255, 255, 0.5);
            padding-left: 15px;
          }

          a {
            color: #FFF;
            opacity: .6;
            padding: 15px 0;

            i,
            .fa {
              margin-left: 5px;
            }
          }

          &.active,
          &:focus,
          &:hover,
          &.show {
            a {
              text-decoration: none;
              opacity: 1;
            }
          }
        }

        .search-input {
          margin-top: 0;
          margin-bottom: 0;
          padding-left: 0;
          display: flex;
          align-items: baseline;
          justify-content: center;
          position: relative;
          cursor: pointer;

          &__elem {
            background: rgba(255, 255, 255, 0);
            border: none;
            color: #FFF;
            width: 130px;
            transition: all ease-in-out .4s;
            padding-right: 20px;
            width: 115px;
            font-size: 14px;

            &::placeholder {
              color: #FFF;
              opacity: .6;
              transition: all ease-in-out .2s;
              font-weight: 300;
            }

            &:focus,
            &:hover {
              outline: none;
              box-shadow: unset;
            }

            &:focus {
              background: #FFF;
              color: #979797;
              border-radius: 0;
              width: 250px;

              &::placeholder {
                opacity: 1;
                color: #979797;
              }

              &+button {
                opacity: 1;
                box-shadow: unset;
                outline: none;
                color: #979797;
                padding-right: 15px;
              }
            }

            &.open {
              background: #FFF;
              color: #979797;
              border-radius: 0;

              &::placeholder {
                opacity: 1;
                color: #979797;
              }
            }
          }

          button {
            background: transparent;
            border: transparent;
            color: #FFF;
            opacity: 0.6;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            padding: 0 15px;
            padding-right: 0;

            &:focus,
            &:hover {
              padding-right: 15px;
              outline: none;
              box-shadow: unset;
            }
          }
          &:hover,
          &:focus {

            button {
              opacity: 1;
              box-shadow: unset;
              outline: none;
            }
            input {
              outline: none;
              box-shadow: unset;

              &::placeholder {
                opacity: 1;
              }
            }
          }
          &:focus {
            button {
              padding-right: 15px;
            }
            input {
              background: #FFF;
              color: #979797;
              border-radius: 0;

              &::placeholder {
                opacity: 1;
                color: #979797;
              }
            }

          }
        }
      }

      @media (max-width: 1201px) {
        height: 35px;

        &__menu,
        .menu {

          &__item {
            margin: 0 15px;
            a {

              span {
                display: none;
              }
            }
          }
        }
      }
    }

    &__bottom {
      background: $red;
      padding: 0 0;
      height: auto;
      position: relative;
      top: 0;
      left: 0;
      right: 0;

      &__menu,
      .menu {

        &__item {

          padding: 15px 5px;
          margin: 0 8px;
          border-bottom: solid 1.5px transparent;

          &:focus,
          &:hover,
          &.active,
          &.show {
            border-bottom: solid 1.5px #FFF;
          }

          a {

            i,
            .fa {
              margin-left: 0;
            }
          }

          &.search-input {
            padding: 0;
            margin: 25px 0 0;
            border: none;
            width: 100%;
            margin-left: 0;

            input,
            form {
              width: 100%;

              &:focus {
                width: 100%;
              }
            }
            button {
              padding-right: 15px;
            }
          }
        }
      }

      &.sticky {
        top: -45px;
      }

      .btn-toggle.mobile {
        display: none;
      }

      .mobile-inline {
        list-style: none;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 768px) {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;

          li {
            padding: 0 10px 0 0;

            a {

              &:hover,
              &:focus,
              &.active {
                border-bottom: none;
              }
            }
          }
        }
      }

      @media (max-width: 1201px) {
        height: 60px;

        &__menu,
        .menu {
          flex-direction: column;
          position: absolute;
          background: $red;
          top: 95%;
          left: 0;
          right: 0;
          z-index: 9;
          transform: scaleY(0);
          transform-origin: top;

          &__item {

            &>a {
              border-bottom: solid 1.5px transparent;
            }

            &:focus,
            &:hover,
            &.active,
            &.show {
              border-bottom: none;

              &>a {
                border-bottom: solid 1.5px #FFF;
              }
            }
          }

          &.open {
            transform: scaleY(1);
          }

          .dropdown-menu.show {
            position: relative;
            top: unset;
            left: unset;
            float: unset;
            background: transparent;
            color: #FFF;
            box-shadow: none;
            margin: 0;
            padding: 0;
            margin-top: 15px;

            a {
              color: $light!important;
              padding: 15px 10px 5px !important;
              border: none;
            }
          }
        }
        & .btn-toggle.mobile {
          display: block;
          float: right;
          color: $light;
          padding: 15px 5px;
          font-size: 18px;

          &:hover,
          &:focus {
            box-shadow: none;
            outline: none;
          }
        }
        &.sticky {
          top: -35px;

          .navbar__bottom__menu.menu.open {
            padding-top: 30px
          }
        }
      }
    }

    &__brand {
      position: absolute;
      top: 0; left: 0; bottom: 0;
      z-index: 9;
      width: 21%;

      &__img_w{
        max-width: 350px;
        margin-top: -5px;
        filter: drop-shadow(0px 0px 3px rgba(191, 13, 3, 0.1));
        @media(max-width: 640px){
          max-width: 250px;
        }
      }

      &__img {
        background: #FFF;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 70px;
        transform: skew(-35deg);
        margin-left: -50px;
        overflow: hidden;
        height: 100%;
        border-bottom-right-radius: 30px;

        img {
          transform: skew(35deg);
          max-width: 100%;
          margin: 0 15px;
          width: 100%;
        }
      }

      &.sticky {

        .navbar__brand__img {
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
      }

      @media (max-width: 1201px) {
        width: 40%;
        height: 0px;

        &__img {
          padding: 5px 25px 0 60px;
          height: 60px;

          img {
            width: 100%;
          }
        }
        &.sticky {
          .img-wrapper {
            height: 100%;
          }
        }
      }

      @media screen and (max-width: 480px){
        width: 40%;
      }
    }

    .dropdown {

      &-menu {
        background: $light;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        border: none;
        border-radius: 0 0 4px 4px;
        top: 100%;
        padding: 0;
      }

      &-item {
        color: $grey!important;
        padding: 10px 15px 10px!important;
        font-size: 14px;
        border-bottom: solid 1px rgba(151, 151, 151, 0.2);
        font-weight: 300;

        &:last-child {
          border-bottom: none;
        }

        &:hover,
        &:focus {
          color: $red!important;
          background: transparent;
        }
      }
    }
  }
}

header .navbar__bottom .menu__item a{
  font-weight: 500;
  font-size: 13px;
}
header .navbar__bottom .menu__item a:hover{
  font-weight: 500;
}
.navbar__top__menu .dropdown-toggle{
  opacity: 1 !important;
}

.navbar_wp{
  ul{
    width: 100%;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    margin: 0;
    padding: 0;
  }
}


.navbar-desktop{
  display: block;
  @media(max-width: 640px){
    display: none;
  }
}
.navbar-mobile{
  display: block;
  @media(min-width: 641px){
    display: none;
  }
}

.navbar-overflow{
  height: 80vh;
  overflow: scroll;
  width: 100%;
  padding-bottom: 15px;
}
.products {
  min-height: 100vh;
  background-color: #FFF;
  overflow: hidden;

  .products__head {
    font-weight: bold;

    @media screen and (max-width: 640px) {
      font-size: 2rem;
    }
  }

  .products__desc {
    font-weight: 300;

    @media screen and (max-width: 640px) {
      padding-left: 10px;
      padding-right: 10px;
    }

    @media screen and (max-width: 480px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__item {
    width: 460px !important;
    margin: 0 10px;
    overflow: hidden;
    transform: skew(-10deg);
    margin-right: -50px;

    &__wrapper {
      background-size: cover;
      margin-left: -50px;
      width: 560px;
      height: 500px;
      transform: skew(10deg);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 25px 60px;
      color: $light;
      font-weight: 300;

    }
    &-conferos{
      background-image: url('..//img/home/products/conferos.jpg');
    }
    &-cortez-1-5{
      background-image: url('..//img/products/cortez/1.5/cortez-1.5.png');
    }
    &-cortez-1-8{
      background-image: url('..//img/products/cortez/1.8/cortez-1.8.png');
    }
    &-formo{
      background-image: url('..//img/products/formo/formo.png');
    }
    &-suv{
      background-image: url('..//img/products/suv/suv.png');
    }
  }

  &__carousel {
    overflow: hidden;
  }

  // .slick-cloned {
  // }

  .slick-slide {
    padding-left: 60px;

    &[data-slick-index="0"] {
      position: relative;

      &:before {
        content: "";
        position: absolute;
        left: -100%;
        top: 0;
        z-index: 9;
        width: 460px !important;
        height: 485px;
        margin: 0 10px;
        overflow: hidden;
        transform: skew(-10deg);
        background: #bf0d03;
        margin-left: 70px;
      }
    }
  }


  .slick-arrow {
    position: absolute;
    z-index: 9;
    background: #000;
    opacity: .6;
    border: 0;
    color: #FFF;
    border-radius: 100%;
    padding: 20px;
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    font-size: 30px;
    cursor: pointer;

    &.slick-prev {
      left: -50px;
      top: -80px;
      bottom: 0;
      margin: auto;
      outline: none !important;
      justify-content: flex-end;
    }

    &.slick-next {
      right: -50px;
      top: -80px;
      bottom: 0;
      margin: auto;
      outline: none !important;
      justify-content: flex-start;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  @media screen and (max-width: 768px) {
    min-height: 100vh;
    padding: 100px 0 20px;

    &__item {
      margin-left: 15px;
      margin-right: 0;
      width: 310px !important;
      // transform: skew(-7deg);

      &__wrapper {
        height: 360px;
        // transform: skew(7deg);
        background-size: 110%;
        background-position-x: 100%;
        background-position-y: top;
      }
    }

    // .slick-track {
    //   margin-left: 10vw;
    // }
    // .slick-slide {
    //   width: 300px;
    //   margin: 0 10px;
    //   overflow: hidden;
    // }

    .slick-slide {
      padding-left: 10px;

      &[data-slick-index="0"] {

        &:before {
          width: 310px !important;
          height: 360px;
          margin-left: 25px;
          transform: skew(-7deg);
        }
      }
    }
  }
}

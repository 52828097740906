.section-contact{
  position: relative;
  min-height: 100vh;
  height: auto;
  background-size: cover !important;
  background-position: center !important;
  padding-bottom: 25px;
  .contact-wrapper{
    border-radius: 4px;
    width: 90%;
    background-color: rgba(255, 255, 255, .83);
    padding: 3% 3.5% 2%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
    .col-custom{
      padding: 5px;
    }
  }
}

.pos-arrow-down{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 3px;
  font-size: 14px;
  font-weight: bolder;
  color: #ccc;
  font-size: 24px;
  &__contact{
    right: 10px;
    padding: 5px;
    color: #000;
  }
}

.group-number{
  position: relative;
  .errmsg{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bb1400;
    font-weight: 400;
    font-size: 15px;
  }
}

.main-products {
  color: $grey;

  .m-scroll {
    @media (max-width: 768px) {
      width: 100%;
      max-width: 100%;
      overflow-x: scroll;
      overflow-y: hidden;
    }
  }

  @media screen and (max-width: 640px) {
    h1 {
      font-size: 2rem;
    }
  }

  .hero {
    min-height: 100vh;
    height: auto;
    background-size: cover;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;

      &.banner-desktop {
        display: block;

        @media(max-width: 640px) {
          display: none;
        }
      }

      &.banner-mobile {
        display: none;

        @media(max-width: 640px) {
          display: block;
        }
      }
    }
  }

  .variant {
    padding: 100px 0;
    text-align: center;
    position: relative;

    &__content {
      @media (max-width: 768px) {
        padding: 15px 0;
      }
    }

    &__image {
      max-width: 100%;

      @media (max-width: 768px) {
        max-width: 80%;
      }
    }

    &__control {
      // transform: skew(-15deg);
      // background-color: $light;
      // box-shadow: 0 16px 54px 0 rgba(0, 0, 0, 0.06);
      margin: 15px 0px;
      padding: 15px 20px;

      @media(max-width: 640px) {
        padding: unset;
      }

      &__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        // transform: skew(15deg);
      }

      @media (max-width: 768px) {

        &__wrapper {
          flex-direction: column;
        }
      }
    }

    &__name {
      margin: 0;
      padding: 15px 0;
      width: 20%;
      text-align: left;

      @media (max-width: 768px) {
        width: 100%;
        padding: 10px 0;
        text-align: center;
      }
    }

    .line-h {
      border-bottom: solid 1px $grey;
      height: 0px;
      width: 45%;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &__controller {
      width: 100%;
      text-align: center;

      .btn-variant {
        background: $grey;
        border-radius: 0;
        border: solid 5px rgba(255, 255, 255, 0);
        transform: skew(-15deg);
        // width: 35px;
        // height: 25px;
        color: #ffffff;
        padding: 10px;
        margin: 0 8px;
        letter-spacing: normal;

        .no-skew {
          transform: skew(15deg);
          display: block;
        }

        &:hover,
        &:focus,
        &.active {
          letter-spacing: normal;
          box-shadow: none;
          border: solid 5px rgba(255, 255, 255, 0.3);
        }
      }

      .black-border-color {
        border: solid 5px #575757;
        color: inherit !important;

        &:hover,
        &:focus,
        &.active {
          border: solid 5px #5757572e;
        }
      }

      @media (max-width: 991px) {
        width: 100%;
        text-align: center;

        .btn-variant {
          font-size: 16px;
          padding: 10px;
          margin: 10px;
        }
      }

      @media (max-width: 640px) {
        width: 100%;
        text-align: center;
        display: flex;

        .btn-variant {
          font-size: 14px;
          padding: 8px;
          margin: 5px;
        }
      }

      @media (max-width: 375px) {
        width: 100%;
        text-align: center;

        .btn-variant {
          font-size: 13px;
          padding: 7px;
          margin: 5px;
        }
      }
    }

    &__navigation {
      position: absolute;
      height: 50px;
      margin: auto;
      top: 0;
      bottom: 0;
      left: -30px;
      right: -30px;
      display: flex;
      justify-content: space-between;
      align-content: center;

      a {
        color: $red;
        font-size: 2rem;
      }

      @media (max-width: 768px) {
        left: 10px;
        right: 10px;
      }
    }
  }

  .overview {

    .nav.nav-tabs {
      display: flex;
      justify-content: space-around;
      align-items: baseline;
      padding: 0 40px;

      .nav-link {
        border: none;
        letter-spacing: 0.5px;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        color: $grey;
        border-bottom: solid 3px transparent;

        &.active {
          color: $red;
          border-bottom: solid 3px $red;
        }
      }

      @media screen and (max-width: 768px) {
        width: max-content;
      }
    }


    .tab-content {

      .tab-pane__desc {

        @media screen and (max-width: 768px) {
          padding-left: 15px;
          padding-right: 15px;
        }
      }

      .content {
        padding: 50px 0;
      }
    }

    &__slider2 {

      &__item2 {
        width: 100%;
        max-width: 100%;
        text-align: center;

        .two-image {
          margin-top: 25px;
          display: flex;
          justify-content: space-around;
        }
        img{
          max-width: 90%;
          margin: auto;
        }
        }
        h1, p{
          color: #0f1011;
        }
        p{
          margin-bottom: 50px;
      }
      
      .red{
        color: #bf0d03;
      }
      .slick-slide {
        opacity: .4;
        img{
          max-width: 90%;
          margin: auto;
        }
      }

      .slick-current,
      .slick-active {
        opacity: 1;
      }

      .slick-arrow {
        position: absolute;
        // bottom: 0;
        bottom: 190px;
        margin: auto;
        height: min-content;
        color: $red;
        font-size: 2rem;
        z-index: 99;

        &.slick-prev {
          left: 15px;
        }

        &.slick-next {
          right: 15px;
        }

        @media screen and (max-width: 768px) {
          top: unset;
          bottom: 5px;
        }

      }
    }



    &__slider {

      &__item2 {
        width: 100%;
        max-width: 100%;
        text-align: center;

        .two-image {
          display: flex;
          justify-content: space-around;
          @media(max-width:768px){
            flex-direction: column;
            justify-content: center;
            align-items: center;
          }
          .left{
            width: 50%;
            @media(max-width: 768px){
              width: 100%;
            }
          }
          .right{
            width: 50%;
            @media(max-width: 768px){
              width: 100%;
            }
          }
        .img-wrapper{
          height: 340px;
          width: 560px;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          max-width: unset;
          margin: auto;
          @media(max-width: 640px){
            width: 90%;
          }
        }
        }
      }

      &__item {
        width: 600px !important;
        padding: 0px 10px;

        img {
          width: 100%;
          max-width: 100%;
        }

        @media (max-width: 768px) {
          max-width: 100%;
          width: 400px !important;
        }

        @media (max-width: 768px) {
          width: 360px !important;
        }
      }

      .slick-slide {
        opacity: .4;
      }

      .slick-current,
      .slick-active {
        opacity: 1;
      }

      .slick-arrow {
        position: absolute;
        bottom: 0;
        top: -100px;
        margin: auto;
        height: min-content;
        color: $red;
        font-size: 2rem;
        z-index: 99;

        &.slick-prev {
          left: 15px;
        }

        &.slick-next {
          right: 15px;
        }

        @media screen and (max-width: 768px) {
          top: unset;
          bottom: 5px;
        }
      }
    }

    &__description {
      margin: 15px 0 5px;

      @media (max-width: 320px) {
        margin: 15px 25px 5px;
      }
    }
  }

  .video {

    &__slider {

      &__item {
        padding: 0 10px;

        a {
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 400px;
          width: 700px;
          display: block;
          position: relative;
          overflow: hidden;

          .play__wrapper {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            background: rgba(0, 0, 0, 0.3);
            color: $red;
            font-size: 73px;

            i {
              background: $light;
              border-radius: 100%;
            }
          }

          @media screen and (max-width: 768px) {
            height: 400px;
            width: 570px;
          }

          @media screen and (max-width: 480px) {
            height: 200px;
            // width: 100%;
          }
        }
      }

      .slick-slide {
        opacity: .4;
      }

      .slick-current,
      .slick-active {
        opacity: 1;
      }
    }
  }

  .specs {
    background: #fefefe;
    margin-bottom: 30px;

    &__image {
      width: 100%;
      max-width: 100%;
      margin: 50px 0 px;

      @media screen and (max-width: 768px) {
        margin: 0;
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: 15px 0;

      .nav.nav-tabs {
        border: none;
      }

      @media screen and (max-width: 768px) {
        flex-direction: column;

        .nav.nav-tabs {
          width: max-content;
          margin-top: 15px;
          padding-bottom: 10px;
        }
      }
    }

    &__tab {

      .nav.nav-tabs {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        border-color: $light;

        .nav-item {
          width: calc(100%/4);
          border: none;
          color: $red;
          text-transform: capitalize;
          text-align: center;
          padding: 20px 15px;

          &.active {
            background: #fefefe;
            box-shadow: 0 12px 54px 0 rgba(0, 0, 0, 0.06);
          }

        }

        @media screen and (max-width: 768px) {
          width: max-content;

          .nav-item {
            width: auto;
          }
        }
      }

      .tab-container {
        background: #fefefe;
        padding: 30px 35px;
        box-shadow: 0 20px 54px 0 rgba(0, 0, 0, 0.06);
        position: relative;

        @media(max-width: 640px) {
          padding: unset;
        }

        .table th,
        .table td {
          border-top: none;
          border-bottom: 1px solid #dee2e6;
        }
      }
    }
  }

  .action {
    padding: 100px 0 100px;

    &__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &__item {
      box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;
      margin: 0 10px;
      transform: skew(-10deg);
      border-bottom: solid 5px #e6e6e6;

      &__wrapper {
        transform: skew(10deg);
        padding: 30px 30px;
        width: 250px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
      }

      &__icon {
        margin: 15px 0;
      }

      &__caption {
        font-family: 'Gotham';
        font-size: 18px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.2px;
        text-align: center;
        color: #575757;

        &:hover,
        &:focus {
          text-decoration: none;
          color: $red;
        }
      }

      &:hover,
      &:focus {
        text-decoration: none;
        border-color: $red;

        .action__item__caption {
          color: $red;
        }
      }

      @media (max-width: 768px) {
        margin: 15px 0;
      }
    }
  }

  .products {
    padding: 50px 0 0px;
  }

  &.products-cortez {
    background: #0f1011;
    color: #FFF;
    font-family: 'Orbitron', sans-serif;

    .hero {
      // background: url('../img/products/cortez/bg-hero.jpg') no-repeat bottom center;
      background-size: 100%;
    }

    .hero-1 {
      // background: url('../img/products/cortez/1.5/bg-hero.jpg') no-repeat bottom center;
      background-size: 100%;
    }

    .variant {
      &__control {
        margin: 15px 0;
        background-color: transparent;
      }

      &__controller {
        .black-border-color {
          border: solid 5px #ffffff;
          color: #575757 !important;

          &:hover,
          &:focus,
          &.active {
            border: solid 5px #b7b7b7;
          }
        }

        .white-border-color {
          border: solid 5px #2d2d2d;

          &:hover,
          &:focus,
          &.active {

            border: solid 5px rgba(255, 255, 255, 0.3);
          }
        }
      }

      &__navigation {
        a {
          color: #FFF;
        }
      }
    }

    .overview {
      .nav.nav-tabs {
        border-bottom: solid 1px rgba($color: #979797, $alpha: .5);

        .nav-link {
          color: #FFF;

          &.active {
            color: $red;
            background: transparent;
          }
        }
      }

      &__slider {

        .slick-arrow {
          color: #FFF;
        }

        .slick-dots {
          li {
            * {
              background: #454545;
            }

            &.slick-active {
              * {
                background: $red;
              }
            }
          }
        }
      }
    }

    .video {
      &__slider {
        .slick-dots {
          li {
            * {
              background: #454545;
            }

            &.slick-active {
              * {
                background: $red;
              }
            }
          }
        }
      }
    }

    .specs {
      background: transparent;
      color: #FFF;

      &__tab {

        .nav.nav-tabs {
          border-color: transparent;

          .nav-item {
            color: #979797;

            &.active {
              background: #1D1F20;
              color: #FFF;
              box-shadow: 0 12px 54px 0 rgba(0, 0, 0, 0.06);
            }

          }
        }

        .tab-container {
          background: #1D1F20;
          box-shadow: 0 12px 54px 0 rgba(0, 0, 0, 0.06);

          .table th,
          .table td {
            border-bottom: 1px solid #979797;
          }
        }
      }
    }

    .action {

      &__item {
        background-color: #1D1F20;
        border-bottom: solid 5px $red;

        &__caption {
          font-family: 'Gotham';
          color: rgba(255, 255, 255, 0.445);

          &:hover,
          &:focus {
            text-decoration: none;
            color: #ffffff;
          }
        }

        &:hover,
        &:focus {
          text-decoration: none;
          border-color: #FFF;

          .action__item__caption {
            color: #FFF;
          }
        }
      }
    }

    .products {
      background: #0f1011;

      .slick-dots {
        li {
          * {
            background: #454545;
          }

          &.slick-active {
            * {
              background: $red;
            }
          }
        }
      }
    }
  }

  &.products-formo {
    background: #ffffff;
    color: $grey;

    .hero {
      // background: url('../img/products/formo/bg-hero.jpg') no-repeat bottom center;
      background-size: 100%;
      min-height: auto;

      @media(max-width: 768px) {
        min-height: 55vh;
      }

      .hero-mobile {
        width: 100%;
        display: block;
        height: auto;

        @media (min-width: 640px) {
          display: none;
        }
      }
    }

    .variant {
      background-image: url('../img/products/formo/bg-2.jpg') no-repeat bottom center;

      &__control {
        margin: 15px 0;
        background-color: transparent;
      }

      .nav {
        width: 100%;
        display: flex;
        justify-content: center;

        .nav-item {
          .nav-link {
            background-color: transparent;
            border-radius: 0;
            color: $grey;
            font-weight: bold;
            margin: 0px 5px;
            border-bottom: 3px solid transparent;
            // &:hover,
            // &:focus{
            //   color: #bf0d03;
            //   border-bottom: 3px solid #bf0d03;
            // }
          }

          .active {
            color: #bf0d03;
            border-bottom: 3px solid #bf0d03;
          }
        }
      }

      &__controller {
        .black-border-color {
          border: solid 5px rgba(183, 183, 183, .5);
          color: #575757 !important;

          &:hover,
          &:focus,
          &.active {
            border: solid 5px #b7b7b7 !important;
          }
        }
      }

      &__navigation {
        a {
          color: #FFF;
        }
      }
    }

    .overview {
      background-color: #f5f5f5;

      .nav.nav-tabs {
        background-color: #ffffff;
        border-bottom: solid 1px rgba($color: #979797, $alpha: .5);

        .nav-link {
          color: $grey;

          &.active {
            color: $red;
            background: transparent;
          }
        }
      }

      .tab-content {

        .tab-pane__desc {
          margin-bottom: 50px;
          color: #000000;

          span {
            color: #bf0d03;
          }

          @media screen and (max-width: 768px) {
            padding-left: 15px;
            padding-right: 15px;
          }
        }

        .content {
          background-color: #f5f5f5;
          padding: 50px 0;
        }
      }

      &__slider {

        .slick-arrow {
          color: $grey;
        }

        .slick-dots {
          li {
            * {
              background: #454545;
            }

            &.slick-active {
              * {
                background: $red;
              }
            }
          }
        }
      }
    }

    .video {
      background-image: url('..//img/products/formo/bg-mid.jpg');
      background-size: 100% 100%;
      padding-top: 50px;
      padding-bottom: 20px;

      .title {
        color: #ffffff;
      }

      .btn-primary:hover,
      .btn-primary:focus,
      .btn-primary.active {
        color: #ffffff !important;
        letter-spacing: normal;
        background-color: $red !important;
        border: none;
      }

      .btn-primary {
        background-color: #dcdcdc;
        border: none;
        color: $grey;
        padding: 10px 30px;
      }

      .nav-item {
        .nav-link {}

        &:active,
        &:focus,
        &:hover {
          .nav-link {}
        }
      }

      &__slider {
        &__item {
          a {
            margin: auto;
          }
        }

        .slick-dots {
          li {
            * {
              background: #454545;
            }

            &.slick-active {
              * {
                background: $red;
              }
            }
          }
        }
      }
    }

    .specs {
      background-image: url('..//img/products/formo/bg-bot.jpg');
      background-size: 100%;
      background-color: #dcdedf;
      background-repeat: no-repeat;
      margin-bottom: 0px;
      padding-bottom: 30px;


      .specs__image {
        margin-top: 90px;
      }
    }

    //   &__header {
    //     margin-top: 30px;
    //     color: $grey;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }

    //   &__tab {

    //     .nav {

    //       .nav-item {
    //         width: 48%;
    //         margin: 0 10px;
    //         text-align: center;
    //         border-bottom: 3px solid #ffffff;
    //         color: red;
    //         color: #bf0d03;
    //         position: relative;

    //         @media (max-width: 1024px) {
    //           width: 47.5%;
    //         }

    //         @media (max-width: 991px) {
    //           width: 47%;
    //         }

    //         @media (max-width: 640px) {
    //           width: 46%;
    //         }

    //         @media (max-width: 480px) {
    //           width: 45.5%;
    //         }

    //         @media (max-width: 425px) {
    //           width: 44.9%;
    //         }

    //         @media (max-width: 375px) {
    //           width: 44%;
    //         }

    //         @media (max-width: 360px) {
    //           width: 43.9%;
    //         }

    //         @media (max-width: 320px) {
    //           width: 43%;
    //         }

    //         &:before {
    //           content: " ";
    //           position: absolute;
    //           bottom: 0;
    //           /* At the top of the tooltip */
    //           left: 50%;
    //           margin-left: -5px;
    //           border-width: 10px;
    //           border-style: solid;
    //           border-color: transparent transparent black transparent;
    //           display: none;

    //           @media (max-width: 425px) {
    //             border-width: 7px;
    //           }
    //         }

    //         &.active {
    //           color: #bf0d03;
    //           border-bottom: 3px solid #bf0d03;

    //           &:before {
    //             display: block;
    //             border-color: transparent transparent #bf0d03 transparent;
    //           }
    //         }

    //       }
    //     }

    //     .tab-container {
    //       background-color: #e7e7e7;
    //       margin: 20px 30px;

    //       .btn-link {
    //         color: $grey;
    //         width: 100%;
    //         display: flex;
    //         letter-spacing: normal;
    //         justify-content: space-between;

    //         i {
    //           text-align: right;
    //         }

    //         &:hover,
    //         &:active,
    //         &:focus {
    //           text-decoration: none;
    //           letter-spacing: normal;
    //         }
    //       }

    //       .table th,
    //       .table td {
    //         border-bottom: 1px solid #979797;
    //       }
    //     }
    //   }
    // }

    .video__slider__item a .play__wrapper i {
      box-shadow: 0px 0px 0px 15px #ff000073;
    }

    // .specs__tab .tab-container {
    //   padding: 0;

    //   .card-header {
    //     background-color: #a8a7a7;
    //     margin: 10px 0px;
    //    }

    //   .card-body {
    //     background-color: #e7e7e7;
    //     color: $grey !important;
    //   }
    // }

    .products {
      background: #0f1011;

      .slick-dots {
        li {
          * {
            background: #454545;
          }

          &.slick-active {
            * {
              background: $red;
            }
          }
        }
      }
    }
  }
}


.main-products .variant {
  padding-top: 65px !important;
}

@media (max-width: 768px) {
  .main-products .hero {
    min-height: 55vh;
  }

  .main-products .variant {
    padding: 50px 0;
  }

  .main-products .overview {
    min-height: unset !important;
  }

  .main-products .products {
    padding: 50px 0;
    min-height: unset;
  }
}

@media (max-width: 641px) {
  .main-products .action__item__caption {
    font-size: 12px !important;
    width: 100px;
  }
}

@media (max-width: 480px) {
  .main-products .hero {
    min-height: 35vh;
  }

  .main-products .video__slider__item {
    height: unset !important;
    padding: 50px 0;
  }
}

@media (max-width: 376px) {
  .main-products .hero {
    min-height: 27vh;
  }
}

// .main-products.products-formo .specs__tab .tab-container .table th,
// .main-products.products-formo .specs__tab .tab-container .table td {
//   border-bottom: none;
//   border-top: 1px solid #979797;
// }
.products__item__wrapper {
  img {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    width: 100%;
    max-width: 100%;
  }
}

.slick-slide img {
  margin-top: 0px !important;
}

.prize {
  @import url('https://fonts.googleapis.com/css?family=Orbitron:400,700');
  padding: 100px 0;

  .img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .wrapper-detail {
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: flex-start;
    flex-direction: column;

    .head {
      font-size: 30px;
      color: #bf0d03;
      font-family: 'Orbitron', sans-serif;
      font-weight: 400;
    }

    .head-1 {
      color: #ffffff;
      font-family: 'Orbitron', sans-serif;
      font-weight: 400;
    }

    .black-text {
      color: #3f3f3f;
    }

    .total {
      font-size: 46px;
      font-family: 'Orbitron', sans-serif;
      font-weight: 700;

      @media(max-width: 425px) {
        font-size: 36px;
      }
    }

    .coment {
      font-size: 15px;
      font-family: 'Orbitron', sans-serif;
      font-weight: 400;
    }
  }
}

.main-products .tab-content>.tab-pane {
  display: block;
  height: 0px;
  overflow: hidden;
}

.main-products .tab-content>.active {
  height: auto;
}


.flexy {
  display: flex;

  @media(max-width: 640px) {
    flex-direction: column;
  }

  .flex-50 {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 5px;

    .sub__title {
      width: 100%;
      text-align: center;
      font-family: Orbitron;
      border: solid 1px #fff;
      padding: 5px 10px;
    }
  }
}

.overview__slider .slick-dots {
  @media(max-width: 640px) {
    display: none !important;
  }
}




.sec-360{
  min-height: 100vh;
  padding: 50px 0;
}

.iframe-controller{
  margin-top: -100px;
  padding: 10px 15px;
  display: flex;
  justify-content: space-around;
  position: relative;
  z-index: 5;
  a{
    outline: none;
    color: #fff;
    &.active{
      border: 2px solid #fff;
    }
    &:hover{
      color: darken($color: #fff, $amount: 40%)
    }
  }
}
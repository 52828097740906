//dealer

.dealer{
    @media(max-width: 640px){
        padding-top: 50px;
    }
    .submit-dealer{
        padding: 7px 23px;
    }
    .row-dealer{
        margin-right: 0;
    }
    .form-control-dealer{
        transform: skew(-15deg);
        height: calc(2.25rem + 4px);
    }
    .dealer-list{
        &__head{
            background-color: #fdfdfd;
            padding: 30px;
        }
        &__item{
            border-bottom: 1px solid #979797;
            padding-bottom: 15px;
            margin-bottom: 20px;
            &:last-child{
                border-bottom: 0;
                margin-bottom: 0;
            }
            a{
                color: #575757;
                font-weight: bold;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        &__body{
            padding: 30px;
            height: 500px;
            overflow-y: scroll;
            overflow-x: hidden;
            @media (max-width: 992px){
                height: unset;
            }
        }
    }
    .dealer-maps{
        margin-left: -30px;
        margin-right: -15px;
    }
    .icon-phone__wrapper{
        display: flex;
        align-items: center;
        p{
            margin-bottom: 0;
            margin-left: 20px;
        }
    }
    #map{
        height: 700px;
        width: 100%;
        @media (max-width: 992px){
            height: 500px;
        }
    }
}
@media (max-width: 992px){
    .col-pr-0{
        padding-right: 0;
    }
}
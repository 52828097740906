.banner {
  position: relative;
  white-space: nowrap;
  overflow: hidden;

  .item {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    // background-image: url('..//img/banner/pic-1.jpg');

    img {
      width: 100%;
      max-width: 100%;
      &.banner-desktop{
        @media(max-width: 640px){
          display: none;
        }
      }
      &.banner-mobile{
        display: none;
        @media(max-width: 640px) {
          display: block;
        }
      }
    }

    .content {
      height: 100%;
      color: $light;
      display: flex;
      align-items: flex-end;

      &__wrapper {
        padding: 3rem 0;
      }
    }

    @media screen and (max-width: 768px) {
      min-height: auto;
      max-height: 100vh;

      .content {
        white-space: normal;
        padding: 0 30px 30px;
      }
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0;
    padding: 0;
    position: absolute;
    bottom: 0%; right: 0;

    li {
      list-style: none;
      width: 30px;
      height: 5px;
      margin: 0 5px;

      * {
        text-indent: -1000em;
        border-radius: 0;
        border: 0;
        background: #FFF;
        outline: none;
        transform: skew(-15deg);
        width: 100%;
        padding: 0;
        margin: 0;
        height: 100%;
      }

      &.slick-active {
        * {
          background: $red;
        }
      }
    }

    @media screen and (max-width: 460px) {
      bottom: 20px !important;
      right: 0 !important;
      left: 0;
      margin: auto;
    }
  }

  &__caption {
    font-size: 1.4rem;
    font-weight: bold;
  }
}

.btn {
  &-social {
    border: solid 1px #FFF;
    transform: skew(-10deg);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px!important;
    border-radius: 5px 0 5px 0;

    span,
    i {
      transform: skew(10deg);
    }

    &:hover,
    &:focus {
      background: #FFF;

      i {
        color: $red;
      }
    }
  }

  &-primary {
    transform: skew(-15deg);
    border-radius: 15px 0 15px 0;
    color: #FFF;
    background: $red;
    padding: 10px 25px;
    border: none;
    margin: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px;
    border-color: $red!important;

    span,
    i {
      display: block;
      transform: skew(15deg);
    }

    span {

    }

    i+span {
      margin-left: 10px;
    }

    &:hover,
    &:focus,
    &.active {
      color: $red!important;
      background-color: #FFF!important;
      box-shadow: none!important;
    }
  }

  &-play {
    box-shadow: 0 0 0 7px rgba(191, 12, 3, 0.3);

    &:hover,
    &:focus {
      box-shadow: 0 0 3px 7px rgba(191, 12, 3, 1);
    }
  }

  &-share {
    background: $grey;
    font-weight: 300;

    i {
      padding-right: 15px;
      border-right: solid 1px $light;
    }

    span {
      padding-left: 5px;
    }

    &__fb {
      background: #3b5999!important;
      border-color: #3b5999!important;

      &:hover,
      &:focus {
        color: #3b5999!important;

        i {
          border-color: #3b5999!important;
        }
      }
    }

    &__tw {
      background: #55acee!important;
      border-color: #55acee !important;

      &:hover,
      &:focus {
        color: #55acee!important;

        i {
          border-color: #55acee!important;
        }
      }
    }
  }

  &-tabs {
    padding: 5px 25px;
    border-radius: 0;
    background: transparent;
    color: $red;

    &:hover,
    &:focus,
    &.active {
      color: $light!important;
      background-color: $red!important;
    }
  }
}

.link {
  color: $red;
  padding: 15px 0;
  margin: 15px 0;

  &:hover,
  &:focus {
    color: $red;
    text-decoration: none;
  }
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin: 40px 0 50px;

  .page-item {
    transform: skew(-10deg);
    margin: 0 5px;
    display: flex;
    overflow: hidden;

    a{
      transform: skew(10deg);
      padding: 10px 20px;
      border: none;
      background: transparent;
      color: $grey;

      &:focus,
      &:hover {
        background: #bf0d03;
        box-shadow: none;
        text-decoration: none;
        color: #fff;
      }
    }

    .page-link {
      transform: skew(10deg);
      padding: 10px 20px;
      border: none;
      background: transparent;
      color: $grey;

      &:focus,
      &:hover {
        box-shadow: none;
      }
    }

    &:focus,
    &:hover,
    &.active {
      background: $red;
      box-shadow: none;

      .page-link {
        box-shadow: none;
        color: $light;
      }
      .a {
        box-shadow: none;
        color: $light;
      }
    }
  }
}

.btn-rounded{
  border-radius: 15px 0 !important;
}
.btn-inverse{
  background: transparent;
  color: #bf0d03 !important;
}
.btn-inverse.active{
  background: #bf0d03 !important;
  color: #fff !important;
}
